import React from 'react';
import { styled } from '@mui/material/styles';
import { Logo } from '@bamboo/core-lib/components/Display/Logo';
import Link from 'next/link';
import type { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import { useHomeMenu } from '@/lib/hooks/menu';

const Root = styled('div')(() => ({
  position: 'relative',
  width: '100%',
  flexWrap: 'wrap',
  alignContent: 'flex-start',
  display: 'flex',
  padding: '0px',
  margin: '20px 0px 0px',
}));

const LogoContainer = styled('div')(() => ({
  position: 'relative',
  width: '100%',
  marginBottom: '10px',
  textAlign: 'left',
  padding: '0 0 3px 0',
}));

const NavContainer = styled('div')(() => ({
  transition:
    'background 0.3s ease 0s, border 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s',
  display: 'flex',
  MozBoxOrient: 'vertical',
  MozBoxDirection: 'normal',
  flexDirection: 'column',
  margin: '20% 0% 10%',
}));

const List = styled('ul')(() => ({
  border: '0px none',
  outline: '0px',
  background: 'transparent',
  fontSize: '100%',
  verticalAlign: 'baseline',
  boxSizing: 'border-box',
  position: 'relative',
  zIndex: 2,
  listStyle: 'none',
  margin: '0px',
  padding: '0px',
  display: 'block',
  marginTop: '0px',
  marginBottom: '0px',
  lineHeight: 'normal',
}));

const ListItem = styled('li')(() => ({
  border: '0px none',
  outline: '0px',
  background: 'transparent',
  fontSize: '100%',
  verticalAlign: 'baseline',
  boxSizing: 'border-box',
  listStyle: 'none',
  margin: '0px',
  padding: '0px',
  display: 'block',
  marginTop: '0px',
  lineHeight: 'normal',
  position: 'relative',
  borderWidth: '0px',
  marginBottom: '4px',
}));

const NavLink = styled(
  (props: LinkProps & { selected?: boolean; children: React.ReactNode }) => {
    const { selected, ...other } = props;
    return <Link {...other} />;
  }
)(({ selected }) => ({
  backgroundColor: 'transparent',
  textDecoration: 'none',
  boxShadow: 'none',
  position: 'relative',
  display: 'flex',
  MozBoxAlign: 'center',
  alignItems: 'center',
  transition: 'all 0.4s ease 0s',
  color: '#727272',
  fill: '#727272',
  fontSize: '1em',
  fontWeight: 400,
  lineHeight: '1.2em',
  padding: '9px 13px',
  ...(selected ? { color: '#FF9966', fill: '#FF9966' } : {}),
}));

const UserNavContainer = styled('div')(() => ({
  boxSizing: 'border-box',
  position: 'relative',
  width: '100%',
  transition:
    'background 0.3s ease 0s, border 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s',
  margin: '25% 0% 10%',
  padding: '0px 0px 0px 5px',
}));

const UserNavLink = styled(
  (props: LinkProps & { selected?: boolean; children: React.ReactNode }) => {
    const { selected, ...other } = props;
    return <Link {...other} />;
  }
)(({ theme, selected }) => ({
  backgroundColor: 'transparent',
  boxSizing: 'border-box',
  textDecoration: 'none',
  boxShadow: 'none',
  display: 'flex',
  MozBoxAlign: 'center',
  alignItems: 'center',
  width: '100%',
  fontSize: '0.6em',
  fontWeight: 400,
  color: 'rgb(102, 102, 102)',
  paddingLeft: '9px',
  ...(selected ? { color: '#FF9966', fill: '#FF9966' } : {}),
}));

const SocialMediaContainer = styled('div')(() => ({
  display: 'flex',
  transition:
    'background 0.3s ease 0s, border 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s',
  lineHeight: 1,
  fontSize: '0px',
  textAlign: 'left',
  margin: '21% 0% 0%',
  padding: '0px 0px 0px 13px',
  '& > *:nth-child(n+2)': {
    marginLeft: '16px',
  },
}));

const SocialMediaLink = styled('a')(() => ({
  transition: 'all 0.3s ease 0s',
  color: 'rgb(129, 138, 145)',
  display: 'inline-flex',
  MozBoxAlign: 'center',
  alignItems: 'center',
  MozBoxPack: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  cursor: 'pointer',
  textDecoration: 'none',
  boxShadow: 'none',
  margin: '0px',
  borderRadius: '10%',
  backgroundColor: 'rgba(255, 255, 255, 0)',
}));

const SideNavigation = () => {
  const router = useRouter();
  const [menu, userMenu, socialMediaMenu] = useHomeMenu();

  return (
    <Root>
      <LogoContainer>
        <Logo height="auto" width="80%" style={{ maxWidth: '251px' }} />
      </LogoContainer>
      <NavContainer>
        <List>
          {menu.map((page, i) => (
            <ListItem key={i}>
              <NavLink href={page.to} selected={router.pathname === page.to}>
                {page.title}
              </NavLink>
            </ListItem>
          ))}
        </List>
      </NavContainer>
      <UserNavContainer>
        <List>
          {userMenu.map((page, i) => (
            <ListItem key={i} style={{ paddingBottom: 'calc(5.5px)' }}>
              <UserNavLink
                href={page.to}
                selected={router.pathname === page.to}
              >
                {page.title}
              </UserNavLink>
            </ListItem>
          ))}
        </List>
      </UserNavContainer>
      <SocialMediaContainer>
        {socialMediaMenu.map((sm, i) => (
          <SocialMediaLink
            key={i}
            href={sm.to}
            target="_blank"
            rel="noopener noreferrer"
          >
            {sm.icon}
          </SocialMediaLink>
        ))}
      </SocialMediaContainer>
    </Root>
  );
};

export default SideNavigation;
